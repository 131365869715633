(function ($, _, root) {
    var _trackingMetadataCache = {};

    root.productListType = {
        productList: 'Product list',
        relatedProducts: 'Related products',
        bestsellers: 'Bestsellers',
        alsoBought: 'Also bought',
        searchResults: 'Search results'
    }

    // Listen to the cart changes and generate notifications to the external tracking listeners
    root.PubSub.subscribe('estore.callback.shopcart',
        function (topic, data) {
            _reportCartChange(topic, data.result.trackableMetadata);
        });
    root.PubSub.subscribe('estore.postback.shopcart',
        function (topic, data) {
            _reportCartChange(topic, data);
        });

    root.PubSub.subscribe('estore.callback.shoppinglist',
        function (topic, data) {
            _reportShoppingListChange(topic, data.result.trackableMetadata);
        });

    root.toProductListType = function (mode) {
        switch (mode) {
        case 'Bestsellers':
            return root.productListType.bestsellers;
        case 'CustomersAlsoBought':
            return root.productListType.alsoBought;
        case 'SearchResults':
        case 'SmartSearchResults':
            return root.productListType.searchResults;
        default:
            return root.productListType.productList;
        }
    }

    // trackingProductsWithLinks should be an array of objects { productId: 123, $links: $() }
    root.reportProductListView = function (productListType, productListName, trackingProductsWithLinks) {
        if (!_.isEmpty(trackingProductsWithLinks)) {
            var productListViewData = {
                currencyCode: root.globalSettings.siteCurrencyCode,
                listType: productListType,
                listName: $.trim(productListName),
                items: []
            }

            var trackingProductIds = _.map(trackingProductsWithLinks, function (product) { return product.productId; });
            $.when(_loadMetadataForTrackingProducts(trackingProductIds))
                .done(function (metadata) {
                    var trackingProductMetadataMap = {};
                    _.each(metadata,
                        function (trackingProduct) {
                            trackingProductMetadataMap[trackingProduct.productId] = trackingProduct;
                        });

                    // Remember to follow the order of tracking products, it is important for the GTM impressions where shown position is being tracked.
                    _.each(trackingProductsWithLinks,
                        function (trackingProduct) {
                            var trackingProductMetadata = trackingProductMetadataMap[trackingProduct.productId];

                            productListViewData.items.push($.extend({}, trackingProduct, trackingProductMetadata));
                        });

                    root.PubSub.publish('externaltracking.product.listview', productListViewData);
                })
                .fail(function () {
                    console.error('Failed to get tracking products metadata while reporting product list view action');
                });
        }
    };

    root.reportProductDetailsView = function (productId) {
        productId = parseInt(productId);

        if (productId > 0) {
            $.when(_loadMetadataForTrackingProducts([productId]))
                .done(function (metadata) {
                    var trackingProductDetails = metadata[0];

                    trackingProductDetails.currencyCode = root.globalSettings.siteCurrencyCode;

                    root.PubSub.publish('externaltracking.product.detailsview', trackingProductDetails);
                })
                .fail(function () {
                    console.error('Failed to get tracking products metadata while reporting product list view action');
                });
        } else {
            throw 'Invalid ProductID value';
        }
    };

    root.reportPaymentOptionSelection = function (step, paymentName) {
        var optionData = {
            step: step,
            isPaymentStep: true,
            option: 'Payment: ' + $.trim(paymentName)
        };

        _reportCheckoutOption(optionData);
    };

    root.reportDeliveryOptionSelection = function (step, deliveryName) {
        var optionData = {
            step: step,
            isDeliveryStep: true,
            option: 'Delivery: ' + $.trim(deliveryName)
        };

        _reportCheckoutOption(optionData);
    };

    root.reporWebshopSearchEvent = function (searchText) {        
        if (searchText?.length > 0) {
            root.PubSub.publish('externaltracking.webshop.search', searchText);
        }
    };

    root.loadMetadataForTrackingProducts = _loadMetadataForTrackingProducts;

    function _reportCartChange(topic, trackableCartChanges) {
        if (_.isEmpty(trackableCartChanges)) {
            return;
        }
        var cartChangeMetadata;
        var channelParts = topic.split('.');
        var topicAction = channelParts[channelParts.length - 1];
        var topicType = channelParts[channelParts.length - 3];
        switch (topicAction.toLowerCase()) {
            case 'addtocart':
                cartChangeMetadata = {
                    source: topicType,
                    currencyCode: root.globalSettings.activeCurrencyCode,
                    items: trackableCartChanges
                };

                root.PubSub.publish('externaltracking.shopcart.addtocart', cartChangeMetadata);
                break;
            case 'emptycart':
            case 'removefromcart':
                cartChangeMetadata = {
                    source: topicType,
                    currencyCode: root.globalSettings.activeCurrencyCode,
                    items: trackableCartChanges
                };

                root.PubSub.publish('externaltracking.shopcart.removefromcart', cartChangeMetadata);
                break;
            case 'updatecartquantity':
                cartChangeMetadata = {
                    source: topicType,
                    currencyCode: root.globalSettings.activeCurrencyCode,
                    addedQtyItems: trackableCartChanges.trackableAddedQtyLinesMetadata,
                    removedQtyItems: trackableCartChanges.trackableRemovedQtyLinesMetadata
                }; 

                root.PubSub.publish('externaltracking.shopcart.updatecartquantity', cartChangeMetadata);
                break;
        }
    }

    function _reportShoppingListChange(topic, trackableCartChanges) {
        if (_.isEmpty(trackableCartChanges)) {
            return;
        }
        var shoppingListChangeMetadata;
        var channelParts = topic.split('.');
        var topicAction = channelParts[channelParts.length - 1];
        var topicType = channelParts[channelParts.length - 3];
        switch (topicAction.toLowerCase()) {
            case 'addtowishlist':
            case 'addtoshoppinglist':
            case 'addshoppinglist':
            shoppingListChangeMetadata = {
                source: topicType,
                currencyCode: root.globalSettings.activeCurrencyCode,
                items: trackableCartChanges
            };

            root.PubSub.publish('externaltracking.shoppinglist.addtowishlist', shoppingListChangeMetadata);
            break;
        }
    }

    function _reportCheckoutOption(optionData) {
        root.PubSub.publish('externaltracking.checkout.option', optionData);
    }

    function _loadMetadataForTrackingProducts(productIds) {
        // Note: the function may return data in other-than-requested order. Consider it in usages.
        var deferred = $.Deferred();

        var productMetadata = [], toFetch = [];

        _.each(productIds,
            function (productId) {
                var cached = _trackingMetadataCache[productId];
                if (cached) {
                    productMetadata.push(cached);
                } else {
                    toFetch.push(productId);
                }
            });

        if (toFetch.length > 0) {
            $.ajax({
                url: root.R + 'api/trackingmetadata/products',
                type: 'GET',
                data: {
                    productIDs: toFetch
                }
            })
                .done(function (fetchedMetadata) {
                    _.each(fetchedMetadata,
                        function (fetched) {
                            // Add to cache
                            _trackingMetadataCache[fetched.productId] = fetched;
                            // Add to results
                            productMetadata.push(fetched);
                        });

                    deferred.resolve(productMetadata);
                })
                .fail(deferred.reject);
        } else {
            deferred.resolve(productMetadata);
        }

        return deferred.promise();
    }
})(jQuery, window._, window);

/* External tracking event bus. The main purpose of this object is to be sure that all tracking systems handled event */
(function (w, factory) {
    w.ExternalTrackingEventBus = factory(w, w._);

    window.PubSub.subscribe('externaltracking.shopcart',
        function (topic, data) {
            if (!w.ExternalTrackingEventBus.hasRegisteredSystems()) {
                if (w.PubSub) {
                    var channelParts = topic.split('.');
                    var topicAction = channelParts[channelParts.length - 1];
                    w.PubSub.publish(w.ExternalTrackingEventBus.channel.event.handled, { topicAction: topicAction.toLowerCase(), data: data });
                }
            }
        });

}(window, function (w, _) {

    var _trackingSystems = {};
    var _topic = 'externaltracking';

    var supportedChannels = {
        event: {
            handled: _topic + '.event.handled'
        }
    };
    var externalTrackingEventBus = {};

    externalTrackingEventBus.channel = supportedChannels;

    externalTrackingEventBus.register = function (trackingSystem) {
        var trackingSystemsObject = _trackingSystems[trackingSystem];
        if (_.isUndefined(trackingSystemsObject)) {
            _trackingSystems[trackingSystem] = {};
        }
    };

    externalTrackingEventBus.eventHandledByAllSystems = function (event) {
        var trackingSystemsArray = _.values(_trackingSystems);

        if (trackingSystemsArray) {
            for (var i = 0; i < trackingSystemsArray.length; ++i) {
                if (!trackingSystemsArray[i][event]) {
                    return false;
                }
            }
        }

        return true;
    };

    externalTrackingEventBus.resetEvent = function (event) {
        var trackingSystemsArray = _.values(_trackingSystems);

        if (trackingSystemsArray) {
            for (var i = 0; i < trackingSystemsArray.length; ++i) {
                if (trackingSystemsArray[i][event]) {
                    trackingSystemsArray[i][event] = false;
                }
            }
        }
    };

    externalTrackingEventBus.eventHandled = function (trackingSystem, event, data) {
        var trackingSystemObject = _trackingSystems[trackingSystem];

        if (!_.isUndefined(trackingSystemObject)) {
            trackingSystemObject[event] = true;
        }

        if (this.eventHandledByAllSystems(event)) {
            this.resetEvent(event);
            if (w.PubSub) {
                w.PubSub.publish(w.ExternalTrackingEventBus.channel.event.handled, { topicAction: event, data: data });
            }
        }
    };

    externalTrackingEventBus.hasRegisteredSystems = function () {
        var trackingSystemsArray = _.values(_trackingSystems);
        return trackingSystemsArray && trackingSystemsArray.length > 0;
    };

    return externalTrackingEventBus;
}));